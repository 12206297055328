<template>
  <MapControl
    v-if="!isPrinting"
    position="right-center"
    class="zoom-buttons">
    <Button
      v-if="!noMaximize"
      :type="fullscreenButtonType"
      :tooltip="(!isFullscreen ? $t('map.general.fullscreen') : $t('map.general.normal'))"
      @click="toggleFullscreen">
      <Icon :name="fullscreenIcon" />
    </Button>

    <Button
      type="white"
      :class="{ 'is-dimmed is-disabled': !canZoomIn }"
      :tooltip="$t('map.general.zoomIn')"
      @click="zoomIn">
      <Icon name="icon-zoom-in" />
    </Button>

    <Button
      type="white"
      :class="{ 'is-dimmed is-disabled': !canZoomOut }"
      :tooltip="$t('map.general.zoomOut')"
      @click="zoomOut">
      <Icon name="icon-zoom-out" />
    </Button>
  </MapControl>
</template>

<script>
import { mapGetters } from 'vuex'
import { MAP_MAX_ZOOM, MAP_MIN_ZOOM } from '@/components/map/map'

import MapControl from './MapControl.vue'

export default {
  components: {
    MapControl
  },

  props: {
    noMaximize: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      map: null
    }
  },

  inject: ['getMap'],

  computed: {
    ...mapGetters({
      isPrinting: 'map/print/isPrinting',
      isFullscreen: 'map/settings/isFullscreen'
    }),

    fullscreenButtonType () {
      return this.isFullscreen ? 'primary' : 'white'
    },

    fullscreenIcon () {
      return this.isFullscreen ? 'icon-minimize-2' : 'icon-maximize-2'
    },

    canZoomIn () {
      if (this.map !== null) {
        return this.map.getZoom() < MAP_MAX_ZOOM
      }
      return false
    },

    canZoomOut () {
      if (this.map !== null) {
        return this.map.getZoom() > MAP_MIN_ZOOM
      }
      return false
    }
  },

  async created () {
    this.map = await this.getMap()
  },

  methods: {
    toggleFullscreen () {
      if (this.isFullscreen) {
        this.$store.dispatch('map/settings/minimize')
      } else {
        this.$store.dispatch('map/settings/maximize')
      }
    },

    zoomIn () {
      this.map.setZoom(this.map.getZoom() + 1)
    },

    zoomOut () {
      this.map.setZoom(this.map.getZoom() - 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.zoom-buttons {
  display: flex;
  flex-direction: column;
  touch-action: none; // disable pinch-to-zoom iOS

  .button {
    margin: 0 0 0.75rem 0 !important;

    &:last-of-type {
      margin: 0 !important;
    }
  }
}
</style>
