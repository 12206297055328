<template>
  <div id="map" class="map">
    <div
      :id="id"
      :ref="id"
      class="map">
    </div>

    <MapTerrainLayer v-if="hasTerrainLayerFeature" />

    <MapZoomControl
      v-if="zoomButtons"
      :noMaximize="noMaximize">
    </MapZoomControl>

    <MapSettingsControl v-model="settingsPanelVisible" />
    <MapFullscreenControl v-if="isFullscreen" />

    <slot v-if="settingsPanelVisible" name="map-settings-panel">
      <MapSettingsPanel />
    </slot>

    <slot />
  </div>
</template>

<script>
/* global google */
import { mapGetters } from 'vuex'
import { MAP_MAX_ZOOM, MAP_MIN_ZOOM, MAP_DEFAULT_ZOOM } from '@/components/map/map'
import mapStyles from '@/components/map/mapStyles'

import MapTerrainLayer from '@/modules/map/components/layers/MapTerrainLayer.vue'
import { PropertyLayer } from '@/components/map/layers/propertyLayer'
import { FEATURE_TERRAIN_LAYER } from '@/modules/subscription/store/subscriptionStore'

import MapZoomControl from './controls/MapZoomControl.vue'
import MapFullscreenControl from './controls/MapFullscreenControl.vue'
import MapSettingsControl from './controls/MapSettingsControl.vue'
import MapSettingsPanel from './settings/MapSettingsPanel.vue'

export default {
  components: {
    MapTerrainLayer,
    MapZoomControl,
    MapFullscreenControl,
    MapSettingsControl,
    MapSettingsPanel
  },

  props: {
    id: {
      type: String,
      default: ''
    },

    zoomButtons: {
      type: Boolean,
      default: true
    },

    noMaximize: {
      type: Boolean,
      default: false
    }
  },

  provide () {
    return {
      getMap: this.getMap
    }
  },

  data () {
    return {
      $map: null,
      promises: [],
      settingsPanelVisible: false,

      terrainLayers: [],
      propertyLayer: null
    }
  },

  computed: {
    ...mapGetters({
      zoom: 'map/settings/zoom',
      mapType: 'map/settings/mapType',
      propertyLayerVisible: 'map/settings/isPropertyLayerVisible',
      scrollZoom: 'map/settings/hasScrollZoom',
      isFullscreen: 'map/settings/isFullscreen',
      hasFeature: 'subscription/hasFeature'
    }),

    hasTerrainLayerFeature () {
      return this.hasFeature(FEATURE_TERRAIN_LAYER)
    }
  },

  watch: {
    mapType (type) {
      this.setMapType(type)
    },

    propertyLayerVisible (visible) {
      this.togglePropertyLayer(visible)
    },

    scrollZoom (state) {
      this.$map.setOptions({ scrollwheel: state })
    }
  },

  mounted () {
    this.initialize()
    this.createLayers()
    this.resolvePromises()
    this.setMapType(this.mapType)
  },

  methods: {
    initialize () {
      const options = {
        zoom: MAP_DEFAULT_ZOOM,
        maxZoom: MAP_MAX_ZOOM,
        minZoom: MAP_MIN_ZOOM,
        disableDefaultUI: true,
        fullscreenControl: false,
        scaleControl: true,
        mapTypeControl: false,
        center: this.getStartPosition(),
        scrollwheel: this.scrollZoom,
        styles: mapStyles
      }

      this.$map = new google.maps.Map(this.$refs[this.id], options)

      this.$map.addListener('click', (event) => {
        this.$emit('click', {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        })
      })
    },

    resolvePromises () {
      this.promises.forEach(resolve => resolve(this.$map))
    },

    getMap () {
      return new Promise(resolve => {
        if (this.$map) {
          resolve(this.$map)
        } else {
          this.promises.push(resolve)
        }
      })
    },

    setMapType (mapType) {
      this.$map.setMapTypeId(mapType)
    },

    createLayers () {
      this.propertyLayer = new PropertyLayer(this.$map)
      this.togglePropertyLayer(this.propertyLayerVisible)
    },

    togglePropertyLayer (visible) {
      this.propertyLayer.setVisible(visible)

      if (visible) document.documentElement.classList.add('map-has-property-layer')
      else document.documentElement.classList.remove('map-has-property-layer')
    },

    getStartPosition () {
      let latLng = this.$store.getters['user/getCurrentPosition']

      if (latLng === null) {
        latLng = { // Center over "Grythyttan", why? don't know.
          lat: 59.7,
          lng: 14.5
        }
      }

      return latLng
    }
  }
}
</script>

<style lang="scss" scoped>
.map {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
