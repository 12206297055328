<template>
  <MapControl position="top-right">
    <a class="delete is-large" @click="minimize"></a>
  </MapControl>
</template>

<script>
import MapControl from './MapControl.vue'

export default {
  components: {
    MapControl
  },

  methods: {
    minimize () {
      this.$store.dispatch('map/settings/minimize')
    }
  }
}
</script>
