<template>
  <MapLabel
    :label="name"
    :location="labelLocation"
    :visible="visible && labelVisible"
    label-class="subarea-label-overlay">
  </MapLabel>
</template>

<script>
/* global google */
import MapLabel from './MapLabel.vue'

export default {
  components: {
    MapLabel
  },

  props: {
    name: {
      type: String,
      default: ''
    },

    visible: {
      type: Boolean,
      default: true
    },

    clickable: {
      type: Boolean,
      default: false
    },

    editable: {
      type: Boolean,
      default: false
    },

    labelVisible: {
      type: Boolean,
      default: true
    },

    coordinates: {
      type: Array,
      required: true
    },

    strokeColor: {
      type: String,
      default: 'black'
    },

    strokeWeight: {
      type: Number,
      default: 1
    },

    strokeOpacity: {
      type: Number,
      default: 1
    },

    fillColor: {
      type: String,
      default: ''
    },

    fillOpacity: {
      type: Number,
      default: 0
    }
  },

  inject: [
    'getMap'
  ],

  data () {
    return {
      $map: null,
      $polygon: null,

      labelLocation: {
        lat: 0,
        lng: 0
      }
    }
  },

  computed: {
  },

  watch: {
    visible (visible) {
      if (this.$polygon) {
        this.$polygon.setVisible(visible)
      }
    }
  },

  async mounted () {
    this.$map = await this.getMap()

    const polygon = new google.maps.Polygon({
      map: this.$map,
      clickable: this.clickable,
      editable: this.editable,
      paths: this.coordinates,
      strokeColor: this.strokeColor,
      strokeWeight: this.strokeWeight,
      strokeOpacity: this.strokeOpacity,
      fillColor: this.fillColor,
      fillOpacity: this.fillOpacity
    })

    if (!polygon) {
      console.error('Failed to create polygon.')
      return
    }

    this.$polygon = polygon

    this.updateLabelPosition()
  },

  destroyed () {
    if (this.$polygon) {
      this.$polygon.setMap(null)
    }
  },

  methods: {
    updateLabelPosition () {
      const center = this.getBounds().getCenter()

      this.labelLocation = {
        lat: center.lat(),
        lng: center.lng()
      }
    },

    getBounds () {
      const bounds = new google.maps.LatLngBounds()
      this.coordinates.forEach(coord => bounds.extend(new google.maps.LatLng(coord.lat, coord.lng)))
      return bounds
    }
  }
}
</script>
