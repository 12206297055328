<template>
  <div class="map-settings-panel" :class="{ 'is-fullscreen': isFullscreen }">
    <Heading size="5" :text="$t('map.settings.title')" />

    <FormField :label="$t('map.settings.mapType')">
      <MapBaseLayerControl />
    </FormField>

    <FormField>
      <MapPropertyLayerControl />
    </FormField>

    <FormField>
      <MapTerrainLayerControl />
    </FormField>

    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import MapBaseLayerControl from '@/modules/map/components/settings/MapBaseLayerControl.vue'
import MapPropertyLayerControl from '@/modules/map/components/settings/MapPropertyLayerControl.vue'
import MapTerrainLayerControl from '@/modules/map/components/settings/MapTerrainLayerControl.vue'

export default {
  components: {
    MapBaseLayerControl,
    MapPropertyLayerControl,
    MapTerrainLayerControl
  },

  computed: {
    ...mapGetters({
      isFullscreen: 'map/settings/isFullscreen'
    })
  }
}
</script>

<style lang="scss" scoped>
.map-settings-panel {
  position: absolute;
  right: calc(1.5rem + 45px + 1.5rem);
  top: 1.5rem;
  width: 320px;
  background-color: white;
  padding: 1.5rem;
  border-radius: 6px;
  border: 1px solid lightgrey;
  z-index: 1; // Must be higher than any <MapControl> or they will be shown above the panel

  &.is-fullscreen {
    top: calc(1.5rem + 45px + 1.5rem) !important;
  }
}
</style>
