<template>
  <MapControl :class="{ 'is-fullscreen': isFullscreen }" position="top-right">
    <Button
      :type="buttonType"
      icon="icon-settings"
      @click="onClick">
    </Button>
  </MapControl>
</template>

<script>
import { mapGetters } from 'vuex'
import MapControl from './MapControl.vue'

export default {
  components: {
    MapControl
  },

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      isFullscreen: 'map/settings/isFullscreen'
    }),

    buttonType () {
      return this.value ? 'primary' : ''
    }
  },

  methods: {
    onClick () {
      this.$emit('input', !this.value)
    }
  }
}
</script>

<style scoped>
.is-fullscreen {
  top: calc(1.5rem + 45px + 1.5rem) !important;
}
</style>
