<template>
  <MapPolygon
    :coordinates="coordinates"
    :stroke-color="strokeColor"
    :stroke-weight="4"
    :clickable="clickable"
    @click="onClick">
  </MapPolygon>
</template>

<script>
/* global google */
import colors from '@/components/map/utils/colors'
import MapPolygon from './MapPolygon.vue'

export default {
  components: {
    MapPolygon
  },

  props: {
    coordinates: {
      type: Array,
      required: true
    },

    fitBounds: {
      type: Boolean,
      default: true
    },

    clickable: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    strokeColor () {
      return colors.boundary
    }
  },

  inject: [
    'getMap'
  ],

  mounted () {
    if (this.fitBounds && this.coordinates.length > 0) {
      this.doFitBounds()
    }
  },

  methods: {
    async doFitBounds () {
      const map = await this.getMap()

      const bounds = new google.maps.LatLngBounds()
      this.coordinates.forEach(coord => {
        bounds.extend(new google.maps.LatLng(coord.lat, coord.lng))
      })

      map.fitBounds(bounds)
    },

    onClick () {
      this.$emit('click')
    }
  }
}
</script>
