/**
 * Maps a Vuex getter and setter to a local variable
 * @param {*} namespace Full path to the module.
 * @param {*} property  Name of the property to map.
 */
export const mapProperty = (namespace, getter, setter) => {
  return {
    get () {
      return this.$store.getters[`${namespace}/${getter}`]
    },

    set (value) {
      this.$store.commit(`${namespace}/${setter}`, value)
    }
  }
}
