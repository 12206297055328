<template>
  <div class="titlebar2">
    <div class="titlebar-wrapper">
      <div class="titlebar-title">
        <h5 class="title is-5 is-marginless">{{ title }}</h5>

        <InlineLoader
          v-if="loading"
          dark
          size="small"
          class="push-left">
        </InlineLoader>
      </div>

      <div class="titlebar-right">
        <UserDropdown />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },

    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.titlebar2 {
  height: auto;
  width: 100%;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  touch-action: none; // disable pinch-to-zoom iOS

  .titlebar-title {
    white-space: nowrap;
    justify-content: flex-start;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
  }

  .titlebar-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 5;
  }

  .titlebar-right {
    display: flex;
    align-items: center;

    > div {
      white-space: nowrap;
      justify-content: flex-start;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
    }
  }

  .titlebar-controllers {
    margin-top: 0.75rem;

    > div {
      white-space: nowrap;
      justify-content: flex-start;
      flex-wrap: nowrap;

      @media screen and (min-width: 769px) {
        justify-content: flex-end;
      }
    }
  }

  .titlebar-divider {
    display: none;

    @media screen and (min-width: 1024px) {
      display: flex;
    }
  }

  .user-dropdown {
    display: none !important;

    @media screen and (min-width: 1024px) {
      display: block !important;
    }
  }

  .buttons,
  .field {
    margin-bottom: 0;

    > .button {
      margin-bottom: 0;
    }
  }
}
</style>
