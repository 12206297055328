<template>
  <div class="map-control" :class="[position]">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      default: 'top-left'
    }
  },

  computed: {
  }
}
</script>

<style scoped>
.map-control {
  position: absolute;
}

.map-control.top-left {
  top: 1.5rem;
  left: 1.5rem;
}

.map-control.left {
  top: 1.5rem;
  left: 1.5rem;
  bottom: 1.5rem;
}

.map-control.top-right {
  top: 1.5rem;
  right: 1.5rem;
}

.map-control.bottom-right {
  bottom: 1.5rem;
  right: 1.5rem;
}

.map-control.bottom-left {
  bottom: 1.5rem;
  left: 1.5rem;
}

.map-control.bottom {
  bottom: 1.5rem;
  left: 1.5rem;
  right: 1.5rem;
}

.map-control.left-center {
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.map-control.right-center {
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.map-control.top-center {
  top: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
}

.map-control.bottom-center {
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
}

.map-control.right {
  right: 1.5rem;
}
</style>
